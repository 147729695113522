<template>
  <div class="" style="z-index: 9999">
    <v-menu offset-y v-if="equipeID != undefined" open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <btnlocked :equipeID="equipeID" :key="equipeID" :params="{on,attrs}">
          <i v-if="idb=='ic'" v-i:ic##20="icon" class="btn-icon-left"></i>
          <i v-if="idb=='duo'" v-i:duo##16="icon" class="btn-icon-left"></i>
          <span>{{title}}</span>
        </btnlocked>
      </template>
      <v-card class="m-0 mt-n1 py-1 m-0" flat v-for="(item,index) in itens" :key="index" @click="$emit('click',item.target)">
        <div class="m-0 p-0" v-if="item.title == 'separator'">
          <hr class="m-0 p-0 mt-1">
        </div>
        <v-container fluid class="m-0 p-0" v-if="item.title != 'separator'">
          <v-row no-gutters align="center" justify="start" class="h28 m-0 p-0">
            <div class="w30 my-0 py-0 ps-2">
              <i v-if="item.idb=='ic'" v-i:ic##20="item.icon"></i>
              <i v-if="item.idb=='duo'" v-i:duo##16="item.icon"></i>
            </div>
            <div class="w220 my-0 py-0 pt-1 ms-0 ps-1 ext-truncate fs-10pt f-lato fw-600">
              {{item.title}}
            </div>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>
    <v-menu offset-y v-if="equipeID == undefined" open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text small class="px-1 text-nonecase" v-bind="attrs" v-on="on" @click="$emit('click')">
          <i v-if="idb=='ic'" v-i:ic##20="icon" class="btn-icon-left"></i>
          <i v-if="idb=='duo'" v-i:duo##16="icon" class="btn-icon-left"></i>
          <span>{{title}}</span>
        </v-btn>
      </template>
      <v-card class="w250" flat>
        <v-card class="m-0 py-1 m-0" flat v-for="(item,index) in itens" :key="index" @click="$emit('click',item.target)">
          <v-container fluid class="m-0 p-0">
            <v-row no-gutters align="center" justify="start" class="h26 m-0 p-0">
              <div class="w30 my-0 py-0 ps-2">
                <i v-if="item.idb=='ic'" v-i:ic##20="item.icon"></i>
                <i v-if="item.idb=='duo'" v-i:duo##16="item.icon"></i>
              </div>
              <div class="w220 my-0 py-0 ms-0 ps-0 text-truncate fs-10pt f-lato fw-600">
                {{item.title}}
              </div>
            </v-row>
          </v-container>
        </v-card>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import btnlocked from "./btn-locked.vue";

export default {
  name: "btnmenuitem",
  components: { btnlocked },
  props: {
    itens: { default: () => [], type: Array },
    title: { default: "vazio", type: String },
    idb: { default: "duo", type: String },
    icon: { default: "warning", type: String },
    equipeID: { default: undefined, type: String }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <v-card flat class="m-0 p-0">
    <v-toolbar dense flat>
      <btnmenuitem title="Minhas Encomendas" idb="ic" icon="fact-check" class="me-3" @click="$emit('modelToggle','minhasencomendas')"/>
      <btnmenuitem title="Minhas UCs" idb="duo" icon="graduation-cap" class="me-3" @click="$emit('modelToggle','minhasucs')"/>
      <btnmenuitem title="Área" idb="ic" icon="home" class="me-3" @click="$emit('modelToggle','AREA')"/>
      <btnmenuitem title="Administração" icon="gear" class="me-3" equipeID="-MgLXWYKX4opnIpDWaZF" :itens="itensArea" @click="itemClick"/>
    </v-toolbar>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import btnlocked from "./btn-locked.vue";
import btnmenuitem from "./btn-menu-item.vue";

export default {
  name: "topmenu",
  components: { btnlocked, btnmenuitem },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      itensArea: [
        { title: "Atividades", idb: "ic", icon: "task", target: "atividades" },
        { title: "Indicações Metas A2", idb: "duo", icon: "target", target: "indicacoes" },
        { title: "Distribuição A2", idb: "duo", icon: "target", target: "distribuicao" },
        { title: "Visualização A2", idb: "duo", icon: "chalkboard-teacher", target: "visualizacao" },
        { title: "Entregas A2", idb: "duo", icon: "envelope", target: "entregas" },
        { title: "Cards", idb: "duo", icon: "chart-line", target: "cards" },
        { title: "Recursos A2", idb: "duo", icon: "chalkboard-teacher", target: "recursosA2" },
        { title: "Importação", idb: "duo", icon: "upload", target: "importacao" },
        { title: "separator" },
        { title: "Recursos A2 - 2021/1", idb: "duo", icon: "users", target: "recursos" }
      ],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    fazAlgo() {
      console.log("fazAlgo");
    },

    itemClick(value) {
      this.$emit('modelToggle',value);
    }
  }
}
</script>

<style scoped>
</style>
